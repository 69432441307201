import React, { useEffect } from 'react';
import { useAnimation, motion } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import './animatedtitle.style.scss';

export default function AnimatedTitle({ animatedTitle }) {
  const ctrls = useAnimation();

  const { ref, inView } = useInView({
    threshold: 0.5,

    triggerOnce: true,
  });

  useEffect(() => {
    if (inView) {
      ctrls.start('visible');
    }

    if (!inView) {
      ctrls.start('hidden');
    }
  }, [ctrls, inView]);

  const wordAnimation = {
    hidden: {},

    visible: {},
  };

  const characterAnimation = {
    hidden: {
      opacity: 0,

      y: `.25em`,
    },

    visible: {
      opacity: 1,

      y: `0em`,

      transition: {
        duration: 1,

        ease: [0.2, 0.65, 0.3, 0.9],
      },
    },
  };

  return (
    <motion.h3 aria-label={animatedTitle} role='heading'>
      {animatedTitle.split(' ').map((word, index) => {
        return (
          <motion.span
            ref={ref}
            className='animated-word'
            aria-hidden='true'
            key={index}
            initial='hidden'
            animate={ctrls}
            variants={wordAnimation}
            transition={{
              delayChildren: index * 0.25,

              staggerChildren: 0.05,
            }}
          >
            {word.split('').map((character, index) => {
              return (
                <motion.span
                  className='animated-character'
                  aria-hidden='true'
                  key={index}
                  variants={characterAnimation}
                >
                  {character}
                </motion.span>
              );
            })}
          </motion.span>
        );
      })}
    </motion.h3>
  );
}
